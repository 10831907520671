// Badge.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DecryptionWrapper from '../container/DecryptionWrapper.js';

import StatefulImage from '../component/StatefulImage.jsx';

import env from '../resource/env.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

import { color, textColor } from '../style/variables.js';
import media from '../style/media.js';

import DefaultBadge from '../../img/badge-default.png';
import LoadingImage from '../../img/loading.png';

const Badge = ({ title = '', icon = '', isImageOnly = false }) => {
  const isStringTitle = typeof title === 'string';

  const titleChildren = title.props?.children;
  const isStringTitleChildren = typeof titleChildren === 'string';

  const imageAlt = isStringTitle
    ? title
    : isStringTitleChildren
      ? titleChildren
      : icon.props?.alt || '';

  const iconElement = useMemo(() => {
    const isStringIcon = typeof icon === 'string';
    if (!isStringIcon) {
      return icon;
    }

    const isPublicIcon = isStringIcon && icon.includes(env.PUBLIC_URL_PREFIX);
    if (isPublicIcon) {
      const [avifSrcSet, webpSrcSet, jpgSrcSet] = [
        MediaAssetFormat.AVIF,
        MediaAssetFormat.WEBP,
        MediaAssetFormat.JPG,
      ].map(format => getPublicSrcSet({ href: icon, size: 64, format }));
      return (
        <StatefulImage>
          <ImageWrapper data-key="loading" width="68" height="68">
            <img src={LoadingImage} alt="loading icon" />
          </ImageWrapper>
          <ImageWrapper data-key="error" width="60" height="60">
            <img src={DefaultBadge} alt="default badge" />
          </ImageWrapper>
          <ImageWrapper data-key="target" width="60" height="60">
            <DecryptionWrapper resourceUrl={jpgSrcSet.src}>
              <source srcSet={avifSrcSet.srcSet} type="image/avif" />
              <source srcSet={webpSrcSet.srcSet} type="image/webp" />
              <img {...jpgSrcSet} alt={imageAlt} />
            </DecryptionWrapper>
          </ImageWrapper>
        </StatefulImage>
      );
    }

    return (
      <StatefulImage>
        <ImageWrapper data-key="loading" width="68" height="68">
          <img src={LoadingImage} alt="loading icon" />
        </ImageWrapper>
        <ImageWrapper data-key="error" width="60" height="60">
          <img src={DefaultBadge} alt="default badge" />
        </ImageWrapper>
        <ImageWrapper data-key="target" width="60" height="60">
          <img src={icon} alt={imageAlt} />
        </ImageWrapper>
      </StatefulImage>
    );
  }, [icon, imageAlt]);

  return (
    <StyledBadge>
      <Icon isImageOnly={isImageOnly}>{iconElement}</Icon>
      {!isImageOnly && <Title>{title}</Title>}
    </StyledBadge>
  );
};

Badge.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isImageOnly: PropTypes.bool,
};

const StyledBadge = styled.div`
  display: inline-block;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Icon = styled.span`
  display: inline-block;
  ${({ isImageOnly }) => {
    if (isImageOnly) {
      return css`
        border-radius: 4px;
        padding: 4px;
        width: 52px;
        height: 52px;
        background-color: #f6f6f6;
        ${media.tablet`
          width: 48px;
          height: 48px;
        `};
      `;
    }
    return css`
      position: relative;
      z-index: 1;
      border: 1px solid ${color.neutral[100]};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: ${color.neutral[100]};
      box-sizing: content-box;
      overflow: hidden;
      ${media.tablet`
        width: 18px;
        height: 18px;
      `};
    `;
  }}
`;

const ImageWrapper = styled.picture`
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.span`
  margin-left: -26px;
  border-radius: 24px;
  padding-left: 28px;
  padding-right: 4px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 14px;
  background-color: ${color.white};
  color: ${textColor.black};
  ${media.tablet`
    margin-left: -20px;
    padding-left: 22px;
    font-size: 12px;
  `};
`;

export default Badge;
